#section-discover .discover-intro {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center; }
  #section-discover .discover-intro .fa-search {
    font-size: 100px;
    color: #efefef; }

#section-discover .band {
  cursor: pointer;
  margin-bottom: 20px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02);
  border: 0;
  border-radius: 0; }

#section-discover .pagination {
  margin: 20px 0; }

#section-discover .panel-headline {
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #efefef;
  margin-bottom: 10px; }

#section-discover .album {
  margin-bottom: 20px; }
  #section-discover .album .album-info h1 {
    font-size: 12px;
    font-weight: 600; }

#section-discover .post {
  margin-bottom: 20px; }
  #section-discover .post h1.post-title {
    font-size: 16px;
    font-weight: 600; }
  #section-discover .post .post-date {
    margin-bottom: 10px; }
  #section-discover .post .post-content {
    font-size: 14px; }
