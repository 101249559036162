.mailmessage-view .mailmessage {
  border-bottom: 1px solid #f3f3f3;
  padding: 0 0 20px 0; }
  .mailmessage-view .mailmessage .mailmessage-title {
    font-size: 22px; }
  .mailmessage-view .mailmessage .mailmessage-from {
    font-size: 14px;
    margin: 0 0 0 10px; }
  .mailmessage-view .mailmessage .mailmessage-date {
    font-weight: 600; }
  .mailmessage-view .mailmessage .mailmessage-content {
    white-space: pre-line;
    margin-top: 20px;
    font-size: 14px; }
