#section-discography .table-albums thead {
  background-color: #efefef; }
  #section-discography .table-albums thead th {
    padding: 5px 10px;
    font-size: 12px;
    color: #777; }

#section-discography .table-albums .album {
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;
  color: #444;
  border-bottom: 1px solid #efefef; }
  #section-discography .table-albums .album > td {
    padding: 20px 10px; }
  #section-discography .table-albums .album .album-name .album-image img {
    width: 70px;
    border-radius: 100px; }
  #section-discography .table-albums .album .album-name .album-title {
    padding-left: 20px; }
    #section-discography .table-albums .album .album-name .album-title h4 {
      margin: 0;
      padding: 0; }
  #section-discography .table-albums .album .more {
    display: inline-block;
    padding: 10px 20px;
    font-size: 20px;
    color: #d2d2d2; }
