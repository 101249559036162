section.section-fans .fan {
  padding: 20px;
  height: 320px;
  position: relative;
  cursor: pointer; }
  section.section-fans .fan:hover {
    background-color: #f1f1f16e; }
  section.section-fans .fan .fan-picture img {
    width: 100%;
    border-radius: 400px;
    background-color: #fff;
    border: 4px solid #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15); }
  section.section-fans .fan .fan-name,
  section.section-fans .fan .fan-info {
    text-align: center; }
  section.section-fans .fan .fan-name {
    font-size: 18px;
    font-weight: 600;
    height: 60px;
    overflow: hidden; }
  section.section-fans .fan .fan-info {
    font-size: 12px;
    color: #666; }
  section.section-fans .fan .fan-actions {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px; }
