section#genre .genre-intro {
  background-image: radial-gradient(at 50% 100%, #5a5a5a, #0e0e0e);
  height: 100px;
  margin: -13px -15px 20px -15px; }
  section#genre .genre-intro .genre-bg {
    background: url(/img/constellations.svg) 50% 10% no-repeat;
    height: 100%; }
  section#genre .genre-intro .container {
    display: flex;
    height: 100%;
    align-items: center; }
    section#genre .genre-intro .container h1 {
      color: white;
      font-weight: 600;
      font-family: 'Roboto'; }

section#genre .album {
  padding: 10px; }
  section#genre .album:hover {
    background-color: #f1f1f16e;
    cursor: pointer; }
