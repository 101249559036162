#section-gallery .gallery img {
  cursor: pointer;
  margin-bottom: 10px; }

#section-gallery #br-popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000000bb;
  z-index: 1031; }
  #section-gallery #br-popup .br-popup-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 90vh;
    background-color: #fff; }
    #section-gallery #br-popup .br-popup-content .card-content {
      max-width: fit-content;
      padding: 20px; }
    #section-gallery #br-popup .br-popup-content .card-image {
      position: relative;
      display: flex;
      justify-content: center; }
      #section-gallery #br-popup .br-popup-content .card-image img {
        max-height: 70vh;
        width: auto; }
