#body-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; }
  #body-container #loading {
    margin-top: -200px; }
    #body-container #loading .loading {
      font-family: Nunito;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase; }
