#section-index .intro {
  margin: -15px -15px 0 -15px;
  height: 430px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02); }
  #section-index .intro .intro_bg {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(/img/home/intro-01.jpg);
    background-size: cover;
    background-position: 50% 60%; }
    #section-index .intro .intro_bg h1 {
      font-size: 80px;
      font-weight: 700;
      color: #fff;
      text-transform: uppercase;
      font-family: 'Roboto';
      padding: 10px 40px;
      background-color: black;
      border: 5px solid white; }

#section-index .testimonials {
  margin-bottom: 20px; }
  #section-index .testimonials .testimonial {
    background-color: #f5f4f2;
    padding: 30px; }
    #section-index .testimonials .testimonial img {
      border-radius: 100px;
      width: 70px; }
    #section-index .testimonials .testimonial .testimonial-from {
      margin-top: 20px; }

#section-index .benefits {
  font-family: 'Roboto';
  background-color: #f5f4f2;
  padding: 20px;
  margin-bottom: 20px; }
  #section-index .benefits h1.title {
    font-size: 38px;
    margin-bottom: 40px; }
  #section-index .benefits h2.subtitle {
    font-size: 18px;
    margin-bottom: 20px;
    max-width: 550px;
    margin: 0 auto; }
  #section-index .benefits .benefit h1 {
    font-size: 22px;
    font-weight: 600; }

#section-index .join-now {
  font-family: 'Roboto';
  padding: 50px; }
  #section-index .join-now h1 {
    font-size: 50px;
    font-weight: 600;
    margin: 0 auto;
    max-width: 550px;
    margin-bottom: 30px; }

#section-index .how-it-works {
  font-family: 'Roboto';
  background-color: #f5f4f2;
  padding: 20px; }
  #section-index .how-it-works h1.title {
    font-size: 38px;
    margin-bottom: 40px; }
  #section-index .how-it-works h2.subtitle {
    font-size: 18px;
    margin-bottom: 20px;
    max-width: 550px;
    margin: 0 auto 30px auto; }
  #section-index .how-it-works .works-example {
    margin-bottom: 70px; }
    #section-index .how-it-works .works-example .works-image img {
      box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02); }
