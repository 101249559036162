#band-studio {
  margin-top: -13px; }

.band-intro {
  height: 270px;
  background-image: url(/img/bandstudio/dmlogo_new.jpg);
  background-size: cover;
  background-position: 50% 50%;
  margin: -15px -15px 20px -15px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02); }

.menu .menu-label {
  text-transform: uppercase;
  color: #999;
  font-size: 14px;
  padding: 0;
  margin: 0; }

.menu .menu-list {
  margin-bottom: 20px; }
  .menu .menu-list a {
    font-size: 14px;
    display: block;
    padding: 4px 10px; }
    .menu .menu-list a:hover, .menu .menu-list a.is-active {
      background-color: #e2e2e2;
      color: #525252;
      text-decoration: none; }
