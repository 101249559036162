.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.86);
  font-size: 14px; }

.navbar {
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02); }
  .navbar .navbar-brand img {
    transform: scale(1.4); }

.my-account {
  font-size: 12px; }

.dropdown-menu {
  font-size: .8rem; }

.nav-item.active {
  font-weight: bold; }

.avatar {
  border-radius: 100%;
  width: 25px;
  height: 25px; }
