.map {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 300px; }
  .map .pinmarker {
    width: 50px;
    cursor: pointer;
    margin: -60px 0 0 -24px; }
  .map .popup {
    padding: 10px;
    font-family: 'Rajdhani', 'Nunito';
    color: #000; }
    .map .popup .event-name {
      font-size: 24px; }
    .map .popup .event-date {
      font-size: 14px; }
    .map .popup .event-description {
      margin: 20px 0 0 0;
      max-width: 600px; }

.map-controls {
  position: fixed;
  font-size: 14px;
  padding: 14px;
  top: 66px;
  left: 0;
  bottom: 0;
  width: 300px;
  background-color: #fff; }
  .map-controls .map-options {
    border-bottom: 1px solid #cdcdcd; }
    .map-controls .map-options .menu-tab {
      display: block;
      padding: 5px 0; }
      .map-controls .map-options .menu-tab.active {
        border-bottom: 2px solid #1e6a93; }
  .map-controls .event-types {
    padding: 10px; }
