.loader-container {
  padding: 20px 0; }
  .loader-container .loader-message {
    margin-bottom: 20px; }
  .loader-container .loading-bars {
    height: 40px; }
    .loader-container .loading-bars .loading-bar {
      animation: loader-bars .5s ease-in infinite alternate;
      width: 8px;
      height: 30px;
      margin-right: 3px;
      transform: scaleY(0.2); }
      .loader-container .loading-bars .loading-bar:nth-child(1) {
        background-color: #1e6a93; }
      .loader-container .loading-bars .loading-bar:nth-child(2) {
        background-color: #3284b1;
        animation-delay: .1s; }
      .loader-container .loading-bars .loading-bar:nth-child(3) {
        background-color: #3d86af;
        animation-delay: .2s; }
      .loader-container .loading-bars .loading-bar:nth-child(4) {
        background-color: #5398bf;
        animation-delay: .3s; }
      .loader-container .loading-bars .loading-bar:nth-child(5) {
        background-color: #6ba3c3;
        animation-delay: .4s; }
      .loader-container .loading-bars .loading-bar:nth-child(6) {
        background-color: #7ba9c3;
        animation-delay: .5s; }
      .loader-container .loading-bars .loading-bar:nth-child(7) {
        background-color: #78b5ca;
        animation-delay: .6s; }
      .loader-container .loading-bars .loading-bar:nth-child(8) {
        background-color: #5ea8c1;
        animation-delay: .7s; }
      .loader-container .loading-bars .loading-bar:nth-child(9) {
        background-color: #94c7d8;
        animation-delay: .8s; }
      .loader-container .loading-bars .loading-bar:nth-child(10) {
        background-color: #b1d7e4;
        animation-delay: .9s; }
      .loader-container .loading-bars .loading-bar:nth-child(11) {
        background-color: #cbe6ef;
        animation-delay: 1s; }
      .loader-container .loading-bars .loading-bar:nth-child(12) {
        background-color: #e3f2f7;
        animation-delay: 1.1s; }
