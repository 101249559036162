@import url(./reactions.css);
html, body, #root {
  height: 100%; }

h1, h2, h3, h4, h5, h6 {
  font-family: Rajdhani;
  padding: 0;
  margin: 0; }

a {
  color: #1e6a93; }

.btn-fair {
  background-color: #eaeaea;
  color: #525252; }

button.btn-outline-bandrabbit {
  background-color: #fff;
  color: #1e6a93;
  border: 1px solid #1e6a93; }

ul, li {
  padding: 0;
  margin: 0;
  list-style: none; }

.breadcrumb {
  background-color: transparent; }

body {
  padding-top: 80px;
  background: #f8f8f8;
  font-family: 'Nunito', sans-serif; }

h1.section-separator {
  border-bottom: 1px solid #cdcdcd;
  font-size: 22px; }

.action-buttons {
  margin: 0 0 20px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #f3f3f3; }

.gen-listing {
  padding: 10px 4px;
  font-size: 14px;
  border-bottom: 1px solid #f3f3f3;
  cursor: pointer; }
  .gen-listing:last-child {
    border: 0; }
  .gen-listing:hover {
    background-color: #f5f5f5; }
  .gen-listing.is-unread {
    font-weight: 600; }
  .gen-listing.is-selected {
    background-color: #1e6a930f; }
  .gen-listing .gen-listing-selector {
    padding: 0 10px; }
    .gen-listing .gen-listing-selector label {
      margin: 0; }
  .gen-listing .gen-listing-icons {
    margin: 0 10px 0 0; }
  .gen-listing .gen-listing-image {
    height: 20px;
    width: 20px;
    overflow: hidden; }
  .gen-listing .gen-listing-title {
    padding: 0 10px; }
    .gen-listing .gen-listing-title img {
      max-height: 20px;
      margin-right: 20px; }
  .gen-listing .gen-listing-from {
    font-weight: 600; }
  .gen-listing .gen-listing-date {
    padding: 0 10px; }

.pagination {
  margin: 20px 0; }

.btn.btn-social {
  color: #fff;
  transition-property: transform;
  transition-duration: .2s, .2s; }
  .btn.btn-social:hover {
    transform: translateY(-5px); }
  .btn.btn-social svg {
    fill: #fff;
    width: 13px; }
  .btn.btn-social.btn-facebook {
    background-color: #3763d2; }
  .btn.btn-social.btn-twitter {
    background-color: #1abcff; }
  .btn.btn-social.btn-instagram {
    background-color: #f8468d; }
  .btn.btn-social.btn-twitch {
    background-color: #7b5dfa; }
  .btn.btn-social.btn-youtube {
    background-color: #fd434f; }
  .btn.btn-social.btn-patreon {
    background-color: #ff7a51; }
  .btn.btn-social.btn-discord {
    background-color: #7289da; }

@keyframes loader-bars {
  0% {
    transform: scaleY(0.2); }
  100% {
    transform: scaleY(1); } }

.post {
  border: 1px solid #efefef;
  border-radius: 10px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02);
  margin-bottom: 20px;
  background-color: #fff; }
  .post:last-child {
    margin-bottom: 0; }
  .post .post-header {
    padding: 20px;
    border-bottom: 1px solid #ececec; }
    .post .post-header .post-from-image img {
      width: 44px;
      height: 44px;
      border-radius: 200px; }
    .post .post-header .post-info .post-title {
      font-size: 16px; }
    .post .post-header .post-info .post-date {
      font-size: 12px; }
  .post .post-nav ul li {
    border-bottom: 1px solid #efefef; }
    .post .post-nav ul li:last-child {
      border-bottom: 0; }
    .post .post-nav ul li.spacer {
      height: 20px;
      border-bottom: 0; }
    .post .post-nav ul li.header {
      padding: 10px 10px 10px 20px;
      color: #999;
      text-transform: uppercase;
      font-size: 12px;
      font-family: Rajdhani;
      text-shadow: 1px 0px 2px #cccccc; }
    .post .post-nav ul li a {
      font-size: 14px;
      display: block;
      cursor: pointer;
      padding: 10px 10px 10px 20px;
      color: #1e6a93; }
      .post .post-nav ul li a:hover {
        background-color: #efefef; }
  .post .post-body {
    padding: 20px;
    font-size: 14px; }
    .post .post-body .post-about {
      font-size: 13px;
      margin-bottom: 20px; }
    .post .post-body .post-about-info {
      font-size: 13px;
      margin-bottom: 10px; }
      .post .post-body .post-about-info:last-child {
        margin-bottom: 0; }
      .post .post-body .post-about-info .post-about-info-title {
        color: #999;
        margin-bottom: 2px; }
    .post .post-body .fan {
      margin-bottom: 20px; }
      .post .post-body .fan:last-child {
        margin-bottom: 0; }
      .post .post-body .fan .fan-cover-image img {
        width: 44px;
        height: 44px;
        border-radius: 200px; }
      .post .post-body .fan .fan-info .fan-name {
        font-size: 14px;
        font-weight: bold;
        font-family: Rajdhani; }
      .post .post-body .fan .fan-info .fan-date {
        font-size: 12px; }
  .post .post-social {
    margin: 20px;
    padding: 10px 0;
    border-top: 1px solid #efefef; }
    .post .post-social .reactions .reaction-item {
      color: #fff;
      width: 25px;
      height: 25px;
      text-align: center;
      font-size: 13px;
      line-height: 27px;
      border-radius: 100%;
      border: 1px solid #fff;
      margin-left: -7px; }
      .post .post-social .reactions .reaction-item:first-child {
        margin-left: 0; }
      .post .post-social .reactions .reaction-item.item-thumbsup {
        background: #f1e767;
        background: -moz-linear-gradient(top, #f1e767 0%, #feb645 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, #f1e767), color-stop(100%, #feb645));
        background: -webkit-linear-gradient(top, #f1e767 0%, #feb645 100%);
        background: -o-linear-gradient(top, #f1e767 0%, #feb645 100%);
        background: -ms-linear-gradient(top, #f1e767 0%, #feb645 100%);
        background: linear-gradient(to bottom, #f1e767 0%, #feb645 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f1e767', endColorstr='#feb645', GradientType=0 ); }
      .post .post-social .reactions .reaction-item.item-smile {
        background: #499bea;
        background: -moz-linear-gradient(top, #499bea 0%, #207ce5 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, #499bea), color-stop(100%, #207ce5));
        background: -webkit-linear-gradient(top, #499bea 0%, #207ce5 100%);
        background: -o-linear-gradient(top, #499bea 0%, #207ce5 100%);
        background: -ms-linear-gradient(top, #499bea 0%, #207ce5 100%);
        background: linear-gradient(to bottom, #499bea 0%, #207ce5 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#499bea', endColorstr='#207ce5', GradientType=0 ); }
      .post .post-social .reactions .reaction-item.item-heart {
        background: #f85032;
        background: -moz-linear-gradient(top, #f85032 0%, #f02f17 71%, #e73827 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, #f85032), color-stop(71%, #f02f17), color-stop(100%, #e73827));
        background: -webkit-linear-gradient(top, #f85032 0%, #f02f17 71%, #e73827 100%);
        background: -o-linear-gradient(top, #f85032 0%, #f02f17 71%, #e73827 100%);
        background: -ms-linear-gradient(top, #f85032 0%, #f02f17 71%, #e73827 100%);
        background: linear-gradient(to bottom, #f85032 0%, #f02f17 71%, #e73827 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f85032', endColorstr='#e73827', GradientType=0 ); }
    .post .post-social .reactions .reaction-count {
      font-size: 12px;
      padding: 0 20px;
      font-weight: bold; }
    .post .post-social .comments {
      font-weight: bold;
      font-size: 12px; }
  .post .post-footer {
    padding: 10px 0;
    border-top: 1px solid #efefef;
    min-height: 40px;
    box-shadow: inset 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px; }
    .post .post-footer .footer-button button {
      color: #666;
      font-size: 14px;
      text-decoration: none; }
