.posts.gen-listings .gen-listing .fa-heart {
  color: red; }

.posts.gen-listings .gen-listing .fa-tv {
  color: green; }

.posts.gen-listings .gen-listing .gen-listing-date {
  text-align: right;
  width: 170px; }

.posts.gen-listings .gen-listing .gen-listing-icons .likes, .posts.gen-listings .gen-listing .gen-listing-icons .views {
  width: 70px; }
