.playlist-item {
  cursor: pointer;
  padding: 10px 10px;
  border-bottom: 1px solid #efefef; }
  .playlist-item:hover {
    background-color: #f8f9fa; }
  .playlist-item:last-child {
    border-bottom: 0;
    margin-bottom: 0; }
  .playlist-item .item-image img {
    width: 45px; }
  .playlist-item .item-info .item-title {
    font-weight: bold;
    font-size: 16px; }
  .playlist-item .item-info .item-album {
    font-size: 12px;
    color: #999; }
  .playlist-item .item-album {
    padding-right: 20px; }
  .playlist-item .item-details .item-duration {
    font-size: 12px; }
  .playlist-item .item-controls {
    font-size: 30px;
    color: #1e6a93; }
