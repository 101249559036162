#music-player {
  position: fixed;
  background-color: #fffffff3;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02);
  height: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px 15px;
  z-index: 10; }
  #music-player .track-info {
    cursor: pointer;
    padding: 10px; }
    #music-player .track-info:hover {
      background-color: #efefef; }
    #music-player .track-info .info-image img {
      height: 40px; }
    #music-player .track-info .info-desc .track-title {
      font-size: 14px;
      font-weight: bold; }
    #music-player .track-info .info-desc .track-band {
      font-size: 12px;
      color: #666; }
    #music-player .track-info .info-link {
      border-left: 1px solid #efefef;
      padding-left: 10px; }
  #music-player .controls {
    font-size: 28px; }
  #music-player .track-progress .progress-duration {
    font-size: 12px;
    font-weight: bold; }
  #music-player .options {
    font-size: 24px; }
  #music-player .close a {
    cursor: pointer; }
