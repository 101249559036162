.calendar .calendar-header .calendar-day {
  padding: 5px 0; }

.calendar .calendar-day {
  width: 14.2857142857%;
  padding: 0; }
  .calendar .calendar-day a {
    display: block;
    padding: 3px 0; }
    .calendar .calendar-day a.gray {
      color: #999; }
    .calendar .calendar-day a:hover, .calendar .calendar-day a.current-day {
      background-color: #1e6a93;
      color: #fff;
      cursor: pointer; }

.calendar .calendar-header {
  padding: 20px 20px 0 20px;
  font-size: 13px; }
  .calendar .calendar-header .calendar-day {
    font-weight: bold; }

.calendar .calendar-body {
  padding: 0px 20px 20px 20px;
  margin-bottom: 20px; }
  .calendar .calendar-body .calendar-week .calendar-day {
    font-size: 14px; }

.calendar .calendar-events {
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  overflow-y: auto;
  max-height: calc(100vh - 350px); }
  .calendar .calendar-events .calendar-event {
    padding: 20px; }
    .calendar .calendar-events .calendar-event:last-child {
      padding-bottom: 20px; }
    .calendar .calendar-events .calendar-event .event-date {
      font-weight: bold;
      margin-bottom: 10px; }
    .calendar .calendar-events .calendar-event .event-info {
      margin-bottom: 10px;
      padding: 3px;
      cursor: pointer; }
      .calendar .calendar-events .calendar-event .event-info:last-child {
        margin-bottom: 0; }
      .calendar .calendar-events .calendar-event .event-info .event-time {
        color: #1e6a93; }
        .calendar .calendar-events .calendar-event .event-info .event-time .event-hours {
          font-weight: bold; }
        .calendar .calendar-events .calendar-event .event-info .event-time .event-ampm {
          font-size: 12px; }
      .calendar .calendar-events .calendar-event .event-info .event-title {
        font-weight: bold;
        text-decoration: underline; }
      .calendar .calendar-events .calendar-event .event-info .event-desc {
        font-size: 12px;
        text-decoration: underline; }
